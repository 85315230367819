<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add new user</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(createUser)"
          @reset.prevent="resetForm"
        >
          <!-- Family Name -->
          <validation-provider
            #default="validationContext"
            name="familyName"
            rules="required"
          >
            <b-form-group label="Full name" label-for="familyName">
              <b-form-input
                id="familyName"
                v-model="data.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <!-- eslint-disable -->
          <validation-provider
            #default="validationContext"
            name="username"
            rules="required"
          >
            <b-form-group label="User type" label-for="type">
              <v-select
                v-model="data.type"
                :options="typeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
            </b-form-group>
          </validation-provider>

          <!-- mobile -->
          <validation-provider
            #default="validationContext"
            name="mobile"
            rules="required|integer"
          >
            <b-form-group label="Phone" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="data.mobile"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="mail">
              <b-form-input
                id="mail"
                v-model="data.mail"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="password"
            rules="required"
          >
            <b-form-group label="password" label-for="password">
              <b-form-input
                id="password"
                v-model="data.password"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
              #default="validationContext"
              name="companyName"
          >
            <b-form-group label="Company title" label-for="companyName">
              <b-form-input
                  id="companyName"
                  v-model="data.companyName"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cansel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { UserCreateRequest } from "@/libs/Api/Users";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { AccountAddUserByAdminRequest } from "@/libs/Api/Account";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,

    // Form Validation
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      typeOptions: [
        {
          label: "normal user",
          value: 1,
        },
        {
          label: "Process control",
          value: 2,
        },
        // {
        //   label: "صاحب محصول",
        //   value: 4,
        // },
      ],
      required,
      alphaNum,
      // email,
      countries,
      data: {
        fullName: "",
        email: "",
        mobile: "",
        type: 1,
        password: "",
        companyName: "",
      },
    };
  },
  methods: {
    createUser() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          let _this = this;

          let accountAddUserByAdminRequest = new UserCreateRequest(_this);
          accountAddUserByAdminRequest.setParams(_this.data);
          accountAddUserByAdminRequest.fetch(
            function (response) {
              if (response.isSuccess) {
                _this.$emit("refetch-data");
                _this.$emit("update:is-add-new-user-sidebar-active", false);
                _this.$toast({
                  component: ToastificationContent,
                  position: "bottom-center",
                  props: {
                    title: `Done`,
                    icon: "CheckIcon",
                    variant: "success",
                    text: `Operation successful`,
                  },
                })
              }
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
  },
  mounted() {
    // console.log(this.roleOptions)
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
